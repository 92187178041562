import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Helmet from 'react-helmet';
import {
  Typography, Divider, Grid, Box,
} from '@mui/material';
import Layout from '../layouts/Default';
import Section from '../components/Section';

const TeamMember = ({ member, imageForMember }) => (
  <React.Fragment>
    <Box
      sx={(theme) => ({
        '& img': {
          borderRadius: theme.spacing(),
        },
      })}
    >
      {
        imageForMember && imageForMember.node
        && imageForMember.node.childImageSharp && imageForMember.node.childImageSharp.gatsbyImageData
        && <GatsbyImage
          image={imageForMember.node.childImageSharp.gatsbyImageData}
        />
      }
    </Box>
    <Box mt={2} mb={1}>
      <Typography
        color="primary"
        sx={() => ({
          fontWeight: 'bold',
          fontSize: 22,
          textAlign: 'center',
        })}
      >
        {member.name}
      </Typography>
    </Box>
    <Box fontWeight={400} fontSize={16} textAlign="center" mb={2}>
      {member.designation}
    </Box>
    <Box fontWeight={500} fontSize={18} textAlign="center">
      {member.description}
    </Box>
    <Divider
      sx={(theme) => ({
        marginTop: theme.spacing(4),
        height: 2,
        backgroundColor: theme.palette.primary.light,
      })}
    />
  </React.Fragment>
);

TeamMember.propTypes = {
  member: PropTypes.object.isRequired,
  imageForMember: PropTypes.object.isRequired,
};

// eslint-disable-next-line
export const TeamPageTemplate = ({ 
  allImageContent,
  title,
  members,
  helmet,
  isPreview,
}) => (
    <Section
      sx={(theme) => ({
        textAlign: 'center',
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      })}
    >
      {helmet || ''}
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    fontSize: '48px',
                    lineHeight: '65px',
                    marginBottom: '4rem',
                  },
                  fontWeight: 'bold',
                  fontSize: '32px',
                  lineHeight: '40px',
                  marginBottom: '1.5rem',
                })}
                variant="h1"
                component="h1"
              >
                {title}
              </Typography>
              <Grid container spacing={7}>
                {members && members.map((member, i) => {
                  const imageForMember = !isPreview ? allImageContent.edges.find(
                    (element) => element.node.childImageSharp.fluid.originalName === member.image,
                  ) : {};
                  return (
                    <Grid item key={i} xs={6} md={4}>
                      <TeamMember
                        member={member}
                        imageForMember={imageForMember}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Section>
);

TeamPageTemplate.propTypes = {
  isPreview: PropTypes.bool,
  title: PropTypes.string,
  helmet: PropTypes.object,
  members: PropTypes.array,
  allImageContent: PropTypes.object.isRequired,
};

const TeamPage = ({ data }) => {
  const {
    mdx: post,
    allFile,
    site: {
      siteMetadata: { title },
    },
  } = data;
  const { frontmatter } = post;
  return (
    <Layout>
      <TeamPageTemplate
        helmet={
          <Helmet title={`Team | ${title}`} />
        }
        allImageContent={allFile}
        {...frontmatter}
      />
    </Layout>
  );
};

TeamPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TeamPage;

// https://github.com/gatsbyjs/gatsby/issues/17783#issuecomment-533837059
export const pageQuery = graphql`
  query TeamPageTemplate {
    site {
      siteMetadata {
        title
      }
    }
    mdx(frontmatter: { templateKey: { eq: "team-page" } }) {
      frontmatter {
        title
        members {
          name
          description
          image
          designation
        }
      }
      body
    }
    allFile(
      filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "team-photos"}}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              placeholder: BLURRED
              layout: CONSTRAINED
            )
            fluid {
              originalName
            }
          }
        }
      }
    }
  }
`;
